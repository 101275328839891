/** @format */

import {Form, Input, Modal, Skeleton, Tooltip, message} from "antd"
import axios from "axios"
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useNavigate} from "react-router-dom"
import addIcon from "../../assests/icons/menuIcons/addIcon.png"
import {UserMenusImage} from "../../components/globalImage"
import {setCurrentWorkspace} from "../../store/slices/workspaceSlice"
import {LOGO, MULTI_USER_ICON} from "../../utils/commonSVG"
import {
  clientMenu,
  commonWorkSpaceSubMenu,
  personalSpaceSubMenu,
} from "../menuList"
import SubMenu from "../subMenu/index"
import SubMenuV2 from "../subMenu/newSubMenu"
import {setCounterDetails} from "../../store/slices/globalCounterSlice"
import {MAX_FILE_SIZE} from "../../utils/commonRoutes"

const Menu = ({subMenuCollapsed, handleSubMenuCollapse}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useSelector(state => state.userData?.token)
  const currentUser = useSelector(state => state?.userData)

  const [currentMenuItem, setCurrentMenuItem] = useState()
  const [showWorkspaceModal, setShowWorkspaceModal] = useState(false)
  const [workSpaceList, setWorkSpaceList] = useState([])
  const [workSpaceListLoading, setWorkSpaceListLoading] = useState(false)
  const [logoImage, setLogoImage] = useState()
  const [loading, setLoading] = useState(false)
  const [workspaceDetails, setWorkspaceDetails] = useState({
    name: "",
    image: "",
  })

  const handleShowWorkspaceModal = () => {
    setShowWorkspaceModal(true)
  }
  const handleCloseWorkspaceModal = () => {
    setShowWorkspaceModal(false)
    setWorkspaceDetails({
      name: "",
      image: "",
    })
    setLogoImage()
  }

  const fetchWorkSpaceList = (params = {}) => {
    setWorkSpaceListLoading(true)

    axios
      .get("/workspace/workspace/", {
        params: {...params},
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        if (res?.data) {
          let workspaces = res?.data?.map(el => {
            return {
              ...el,
              url: `${el?.id}/home`,
              id: el?.id,
              submenu: el?.is_personal
                ? personalSpaceSubMenu
                : el?.role == 2
                ? commonWorkSpaceSubMenu
                : commonWorkSpaceSubMenu.filter(
                    el => !el?.url?.includes("workspace"),
                  ),
              type: "workspace",
            }
          })
          let personalWorkSpace = workspaces.filter(el => el?.is_personal)?.[0]
          setWorkSpaceList(workspaces)
          if (localStorage?.getItem("navigateTo") === "jobs") {
            dispatch(setCurrentWorkspace(personalWorkSpace))
            sessionStorage.setItem(
              "current_workspace",
              JSON.stringify(personalWorkSpace),
            )
          }
          if (localStorage?.getItem("redirection_workspace") !== null) {
            let redirectionWorkspace = workspaces.filter(
              el => el?.id === localStorage?.getItem("redirection_workspace"),
            )?.[0]
            dispatch(setCurrentWorkspace(redirectionWorkspace))
            sessionStorage.setItem(
              "current_workspace",
              JSON.stringify(redirectionWorkspace),
            )
          } else {
            dispatch(
              setCurrentWorkspace(
                sessionStorage.getItem("current_workspace")
                  ? JSON.parse(sessionStorage.getItem("current_workspace"))
                  : workspaces[0],
              ),
            )
            if (!sessionStorage.getItem("current_workspace")) {
              sessionStorage.setItem(
                "current_workspace",
                JSON.stringify(workspaces[0]),
              )
            }
          }
          sessionStorage.setItem(
            "personalWorkspace",
            JSON.stringify(personalWorkSpace),
          )
          setCurrentMenuItem(
            sessionStorage.getItem("current_workspace")
              ? JSON.parse(sessionStorage.getItem("current_workspace"))
              : workspaces[0],
          )
        }
      })
      .catch(err => console.error(err?.message))
      .finally(() => {
        setWorkSpaceListLoading(false)
      })
  }
  const handleWorkspaceImageChange = event => {
    const file = event.target.files[0]
    if (file?.size > MAX_FILE_SIZE) {
      message.error("Image must be less than 20 MB")
      return
    }
    if (!file.type.includes("image")) {
      message.error("Please select images only")
      return
    }

    setLogoImage(file)
    if (file) {
      const reader = new FileReader()

      reader.onload = e => {
        const newImageUrl = e.target.result
        setWorkspaceDetails({
          ...workspaceDetails,
          image: newImageUrl,
        })
      }

      reader.readAsDataURL(file)
    }
  }

  const handleCreateWorkspace = () => {
    if (!workspaceDetails?.name.trim().length) {
      message.error("Please fill the name")
      return
    }
    setLoading(true)
    let formData = new FormData()
    formData.append("name", workspaceDetails?.name)
    if (logoImage) {
      formData.append("image", logoImage)
    }
    axios
      .post("/workspace/workspace/", formData)
      .then(res => {
        if (res?.status === 201) {
          message.success("Workspace added successfully")
          fetchWorkSpaceList()
          handleCloseWorkspaceModal()
          setWorkspaceDetails({
            name: "",
            image: "",
          })
        }
      })
      .catch(err => {
        message.error(err?.response?.data?.message ?? err.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (
      !currentUser?.is_client ||
      localStorage.getItem("redirection_workspace") !== null
    ) {
      fetchWorkSpaceList()
    }
  }, [currentUser])

  useEffect(() => {
    setCurrentMenuItem(
      sessionStorage.getItem("current_workspace")
        ? JSON.parse(sessionStorage.getItem("current_workspace"))
        : "",
    )
  }, [window.location.pathname])

  return (
    <div className="d-flex">
      {!currentUser?.is_client && (
        <div
          className="th-bg-grey d-flex"
          style={{
            height: "100vh",
          }}>
          <div
            className="overflow-hidden position-relative"
            style={{
              width: 54,
              height: "100vh",
            }}>
            <div
              className="row"
              style={{
                paddingLeft: 12,
              }}>
              <div className="position-relative py-3">
                <Tooltip placement="right" title="CoGig">
                  <div className="col-12 d-flex justify-content-center">
                    <LOGO />
                  </div>
                </Tooltip>
              </div>
            </div>
            {workSpaceListLoading
              ? [...Array(4)?.keys()]?.map((el, i) => (
                  <Skeleton.Button
                    active
                    block
                    className="mb-2"
                    shape="square"
                    style={{
                      height: 42,
                      width: 42,
                    }}
                    key={i}
                  />
                ))
              : workSpaceList?.map((menu, i) => (
                  <div key={i} className="my-2">
                    <div
                      onClick={() => {
                        dispatch(setCurrentWorkspace(menu))
                        sessionStorage.setItem(
                          "current_workspace",
                          JSON.stringify(menu),
                        )
                        sessionStorage.removeItem("currentSubMenu")
                        localStorage.removeItem("proposal_id")
                        localStorage.removeItem("redirection_workspace")
                        navigate(`${menu?.id}/home`)
                      }}>
                      <Tooltip placement="right" title={menu?.name}>
                        <div
                          key={menu.id}
                          onClick={() => {
                            setCurrentMenuItem(menu)
                            if (!menu?.submenu?.length > 0) {
                              handleSubMenuCollapse(true)
                            } else {
                              handleSubMenuCollapse(false)
                            }
                          }}>
                          <div className="position-relative p-0">
                            <div
                              className="col-12 text-center th-pointer"
                              style={{
                                paddingLeft: 12,
                              }}>
                              <div className="w-100 d-flex align-items-center justify-content-center th-br-8 overflow-hidden">
                                {menu?.is_personal ? (
                                  currentUser?.avatar ? (
                                    <img
                                      src={currentUser?.avatar}
                                      loading="lazy"
                                      alt={"user_logo"}
                                      style={{
                                        height: 42,
                                        width: 42,
                                        objectFit: "cover",
                                      }}
                                    />
                                  ) : (
                                    <UserMenusImage size={42} />
                                  )
                                ) : menu?.image ? (
                                  <img
                                    src={menu?.image}
                                    alt={menu?.name}
                                    loading="lazy"
                                    style={{
                                      height: 42,
                                      width: 42,
                                      objectFit: "cover",
                                      borderRadius: 8,
                                    }}
                                  />
                                ) : (
                                  <div
                                    style={{
                                      width: 42,
                                      height: 42,
                                    }}
                                    className={`d-flex align-items-center justify-content-center th-bg-white th-br-8`}>
                                    <MULTI_USER_ICON size={32} />
                                  </div>
                                )}
                              </div>
                            </div>
                            {currentMenuItem?.id === menu?.id && (
                              <div
                                className="position-absolute th-bg-primary me-5"
                                style={{
                                  width: "3px",
                                  height: "70%",
                                  top: "15%",
                                  borderRadius: "0px 4px 6px 0px",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                    {menu?.is_personal && (
                      <div
                        className="col-12"
                        style={{
                          paddingLeft: 12,
                        }}>
                        <div
                          className="th-bg-grey-3 mx-auto my-2"
                          style={{height: 3, borderRadius: 2, width: 32}}>
                          {" "}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            <Tooltip placement="right" title={"Add Workspace"}>
              <div key={"add_new_icon"} onClick={handleShowWorkspaceModal}>
                <div className="position-relative">
                  <div
                    className="col-12 text-center th-pointer"
                    style={{
                      paddingLeft: 12,
                    }}>
                    <div className="w-100 d-flex align-items-center justify-content-center">
                      <img
                        src={addIcon}
                        alt={"add_icon"}
                        style={{height: 42, width: 42, objectFit: "cover"}}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Tooltip>
          </div>
          <div
            className="th-bg-grey"
            style={{
              width: 12,
              height: "100vh",
              background:
                "linear-gradient(270deg, #D5D9EB 0%, rgba(213, 217, 235, 0) 100%)",
            }}
          />
        </div>
      )}
      <SubMenuV2
        subMenuCollapsed={subMenuCollapsed}
        currentMenuItem={currentMenuItem}
        subMenuList={
          currentUser?.is_client ? clientMenu : currentMenuItem?.submenu
        }
        handleSubMenuCollapse={handleSubMenuCollapse}
        workSpaceListLoading={workSpaceListLoading}
      />

      <Modal
        title="Create new workspace"
        open={showWorkspaceModal}
        onCancel={handleCloseWorkspaceModal}
        onOk={handleCreateWorkspace}
        okButtonProps={{loading: loading}}
        okText="Create">
        <div className="p-3">
          <Form layout="vertical">
            <div className="row align-items-end">
              <div className="col-3 text-center">
                <label className="th-fw-500 th-pointer">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={e => {
                      handleWorkspaceImageChange(e)
                    }}
                    value=""
                    name="images"
                    id="file-upload"
                    style={{display: "none"}}
                  />

                  <div
                    className="th-border th-br-5 p-2 d-flex justify-content-center align-items-center flex-column"
                    style={{background: "#f2f2f2", width: 100, height: 100}}>
                    {workspaceDetails?.image ? (
                      <div
                        style={{
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundImage: `url(${workspaceDetails?.image})`,

                          height: "100%",
                          width: "100%",
                          borderRadius: 6,
                        }}
                      />
                    ) : (
                      <>
                        <svg
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none">
                          <path
                            d="M2 5.2c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874C3.52 2 4.08 2 5.2 2h5.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874C14 3.52 14 4.08 14 5.2v5.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C12.48 14 11.92 14 10.8 14H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C2 12.48 2 11.92 2 10.8V5.2Z"
                            stroke="#717BBC"
                            strokeWidth={0.667}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6 6.333 4 10h8L9 5 7.333 7.333 6 6.333Z"
                            stroke="#717BBC"
                            strokeWidth={0.667}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="th-blue-2 th-12 py-2">
                          Add Workspace logo
                        </div>
                      </>
                    )}
                  </div>
                </label>
              </div>
              <div className="col-9">
                <Form.Item label="Workspace name">
                  <Input
                    value={workspaceDetails?.name}
                    placeholder="Workspace name"
                    onChange={e => {
                      setWorkspaceDetails({
                        ...workspaceDetails,
                        name: e.target.value,
                      })
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default Menu
