/** @format */

import {ArrowRightOutlined} from "@ant-design/icons"
import {Button, Card, Divider, Popover, Skeleton, Tooltip, message} from "antd"
import axios from "axios"
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Link, useLocation, useNavigate} from "react-router-dom"
import {
  MultiUserLogo,
  UserImage,
  UserMenusImage,
} from "../../components/globalImage"
import {setGlobalLoading} from "../../store/slices/globalDataSlice"
import {setCurrentWorkspace} from "../../store/slices/workspaceSlice"
import {
  CLIENT_ICON,
  DISCOVER_ICON,
  EDIT_ICON,
  HEART_IN_BOX_ICON,
  HOME_ICON,
  INVOICES_ICON,
  LOGOUT_ICON,
  NETWORK_ICON,
  PAYMENT_ICON,
  PROJECTS_ICON,
  PROPOSAL_ICON,
  QUESTION_IN_BOX_ICON,
  SETTINGS_ICON,
  SWAP_ICON,
  TEAM_ICON,
  WORKSPACE_ICON,
  PRO_ICON,
  SUPPORT_ICON,
  THREE_VERTICAL_DOTS_ICON,
  OPPORTUNITY_ICON,
  ROCKET_ICON,
  JOB_ICON,
} from "../../utils/commonSVG"
import {TAWK_CHAT} from "../../utils/commonRoutes"
const SubMenu = ({
  subMenuList,
  currentMenuItem,
  handleSubMenuCollapse,
  workSpaceListLoading,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const DefaultColor = "#667085"
  const SelectedColor = "#3538CD"
  const [showPopover, setShowPopover] = useState(false)
  const [showSupportPopover, setShowSupportPopover] = useState(false)

  const currentUser = useSelector(state => state?.userData)
  const currentWorkspace = useSelector(state => state?.workSpaceData)
  const {pathname} = useLocation()
  const currentURL = pathname?.split(`/`)

  const handleUserView = value => {
    dispatch(setGlobalLoading(true))
    localStorage.removeItem("proposal_id")
    localStorage.removeItem("redirection_workspace")
    axios
      .put(`/user/user-detail/${currentUser?.id}/`, {
        is_client: value,
      })
      .then(res => {
        if (res?.status === 200) {
          navigate("/home")
          dispatch({type: "GET_USER_DATA"})
        }
      })
      .catch(err => {
        message.error(err.message)
      })
      .finally(() => {
        // dispatch(setGlobalLoading(false))
      })
  }
  const handleRedirectiontoSettings = ({tabIndex}) => {
    dispatch(
      setCurrentWorkspace(
        JSON.parse(sessionStorage.getItem("personalWorkspace")),
      ),
    )
    sessionStorage.setItem(
      "current-Workspace",
      JSON.stringify(JSON.parse(sessionStorage.getItem("personalWorkspace"))),
    )
    navigate(
      `/${
        JSON.parse(sessionStorage.getItem("personalWorkspace"))?.id
      }/settings`,
      {
        state: {currentTab: tabIndex},
      },
    )
  }

  const Dropdown = () => {
    return (
      <>
        <div>
          <div
            className="d-flex align-items-center gap-2 th-pointer py-2 px-3"
            onClick={() => {
              setShowPopover(false)
              handleUserView(!currentUser?.is_client)
            }}>
            <SWAP_ICON />
            <span className="th-fw-500 th-14 th-black">
              {currentUser?.is_client
                ? "Switch to workspace"
                : "Switch to hiring"}
            </span>
          </div>
          <div className="th-br-2 th-bg-grey" style={{height: 1}} />
        </div>
        {!currentWorkspace?.is_personal && !currentUser?.is_client && (
          <div>
            <div
              className="d-flex align-items-center gap-2 th-pointer py-2 px-3"
              onClick={() => {
                setShowPopover(false)
                navigate(`${`/${currentWorkspace?.id}`}/workspace`, {
                  state: {
                    currentTab: "2",
                  },
                })
              }}>
              <EDIT_ICON />
              <span className="th-fw-500 th-14 th-black">Edit logo & name</span>
            </div>
            <div className="th-br-2 th-bg-grey" style={{height: 1}} />
          </div>
        )}
        <div>
          <div
            className="d-flex align-items-center gap-2 th-pointer py-2 px-3"
            onClick={() => {
              setShowPopover(false)
              navigate(
                `${
                  currentUser?.is_client ? "" : `/${currentWorkspace?.id}`
                }/settings`,
              )
            }}>
            <SETTINGS_ICON />
            <span className="th-fw-500 th-14 th-black">Account settings</span>
          </div>
          <div className="th-br-2 th-bg-grey" style={{height: 1}} />
        </div>
        <Link
          to="https://tally.so/r/mJAOeK"
          onClick={() => {
            setShowPopover(false)
          }}
          target="_blank">
          <div>
            <div
              className="d-flex align-items-center gap-2 th-pointer py-2 px-3"
              onClick={() => {}}>
              <HEART_IN_BOX_ICON />
              <span className="th-fw-500 th-14 th-black">Submit feedback</span>
            </div>
            <div className="th-br-2 th-bg-grey" style={{height: 1}} />
          </div>
        </Link>
        <Link
          to={"https://tawk.to/chat/65aff0eb0ff6374032c3dc6e/1hkrka6sf"}
          style={{
            setShowPopover: false,
          }}
          target="_blank">
          <div>
            <div className="d-flex align-items-center gap-2 th-pointer py-2 px-3">
              <QUESTION_IN_BOX_ICON />
              <span className="th-fw-500 th-14 th-black">Help</span>
            </div>
            <div className="th-br-2 th-bg-grey" style={{height: 1}} />
          </div>
        </Link>
        <div>
          <div
            className="d-flex align-items-center gap-2 th-pointer py-2 px-3"
            onClick={() => {
              setShowPopover(false)
              dispatch({type: "USER_LOGOUT"})
            }}>
            <LOGOUT_ICON />
            <span className="th-fw-500 th-14 th-black">Log-Out</span>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="submenu_bar">
        <div
          className="d-flex justify-content-between align-items-center th-border-bottom mb-2"
          style={{height: 56}}>
          <div className="col-3 text-center">
            {currentUser?.is_client ? (
              <UserMenusImage size={32} color={"#293056"} className={"w-100"} />
            ) : !currentWorkspace?.is_personal ? (
              <MultiUserLogo size={32} color={"#293056"} className={"w-100"} />
            ) : (
              <UserMenusImage size={32} color={"#293056"} className={"w-100"} />
            )}
          </div>

          <div className="col-8 text-left th-fw-600 th-blue-3 ">
            <div className="text-start text-truncate th-14 th-width-95">
              <Tooltip
                title={
                  currentUser?.is_client || currentWorkspace?.is_personal
                    ? `${currentUser?.first_name} ${currentUser?.last_name}`
                    : currentWorkspace?.name
                }
                placement="right">
                {currentUser?.is_client || currentWorkspace?.is_personal
                  ? `${currentUser?.first_name.concat(
                      " ",
                      currentUser?.last_name,
                    )}`
                  : currentWorkspace?.name}
              </Tooltip>
            </div>

            <div className="th-12 th-grey text-start th-fw-500">
              {currentUser?.is_client ? (
                "Hiring Workspace"
              ) : workSpaceListLoading ? (
                <Skeleton.Button
                  active
                  block
                  shape="square"
                  style={{height: 20, width: "90%"}}
                />
              ) : currentWorkspace?.is_personal ? (
                "Personal Workspace"
              ) : (
                "Team Workspace"
              )}
            </div>
          </div>

          <div className="col-1 text-start">
            <Popover
              content={() => Dropdown()}
              placement="rightBottom"
              arrow={false}
              open={showPopover === currentWorkspace.id}
              onOpenChange={open => {
                if (open) {
                  setShowPopover(currentWorkspace.id)
                } else {
                  setShowPopover(false)
                }
              }}>
              <div className="text-start th-pointer">
                <THREE_VERTICAL_DOTS_ICON />
              </div>
            </Popover>
          </div>
        </div>

        {workSpaceListLoading
          ? [...Array(subMenuList?.length)?.keys()]?.map((el, i) => (
              <Skeleton.Button
                active
                block
                shape="square"
                style={{height: 30, width: "80%", marginBottom: 10}}
                key={i}
              />
            ))
          : subMenuList?.map((menu, i) => {
              if (
                (currentWorkspace?.role !== 2 &&
                  menu.name === "Proposals" &&
                  !currentUser?.is_client) ||
                (currentWorkspace?.role !== 2 && menu.name === "Payroll")
              )
                return null
              return (
                <div key={i}>
                  <Link
                    to={
                      menu.url
                        ? currentUser?.is_client
                          ? menu.url
                          : currentMenuItem?.type === "workspace"
                          ? `${currentMenuItem?.id}${menu.url}`
                          : menu.url
                        : null
                    }
                    onClick={() => {
                      sessionStorage.setItem(
                        "currentSubMenu",
                        JSON.stringify(menu),
                      )
                    }}>
                    <div className={`px-2 text-center `}>
                      <div
                        className={`d-flex align-items-center py-2 px-1 position-relative ${
                          menu?.url ? "submenu-item" : ""
                        } ${
                          currentURL.includes(
                            menu.name?.split(" ")[0]?.toLowerCase(),
                          )
                            ? "th-primary th-bg-blue-4  th-br-8"
                            : "th-grey-1 th-br-8"
                        }`}>
                        {menu?.url && (
                          <div className="mx-2 d-flex">
                            {menu?.name === "Home" && (
                              <HOME_ICON
                                color={
                                  currentURL.includes(menu.name?.toLowerCase())
                                    ? SelectedColor
                                    : DefaultColor
                                }
                              />
                            )}
                            {menu?.name === "Network" && (
                              <NETWORK_ICON
                                color={
                                  currentURL.includes(menu.name?.toLowerCase())
                                    ? SelectedColor
                                    : DefaultColor
                                }
                              />
                            )}
                            {menu?.name === "Projects" && (
                              <PROJECTS_ICON
                                color={
                                  currentURL.includes(menu.name?.toLowerCase())
                                    ? SelectedColor
                                    : DefaultColor
                                }
                              />
                            )}
                            {menu?.name === "Payments" && (
                              <PAYMENT_ICON
                                color={
                                  currentURL.includes(menu.name?.toLowerCase())
                                    ? SelectedColor
                                    : DefaultColor
                                }
                              />
                            )}
                            {menu?.name === "Transactions" && (
                              <INVOICES_ICON
                                color={
                                  currentURL.includes(menu.name?.toLowerCase())
                                    ? SelectedColor
                                    : DefaultColor
                                }
                              />
                            )}
                            {menu?.name === "Proposals" && (
                              <PROPOSAL_ICON
                                color={
                                  currentURL.includes(menu.name?.toLowerCase())
                                    ? SelectedColor
                                    : DefaultColor
                                }
                              />
                            )}
                            {menu?.name === "Team" && (
                              <TEAM_ICON
                                color={
                                  currentURL.includes(menu.name?.toLowerCase())
                                    ? SelectedColor
                                    : DefaultColor
                                }
                              />
                            )}
                            {menu?.name === "Clients" && (
                              <CLIENT_ICON
                                color={
                                  currentURL.includes(menu.name?.toLowerCase())
                                    ? SelectedColor
                                    : DefaultColor
                                }
                              />
                            )}
                            {menu?.name === "Jobs" && (
                              <JOB_ICON
                                color={
                                  currentURL.includes(menu.name?.toLowerCase())
                                    ? SelectedColor
                                    : DefaultColor
                                }
                              />
                            )}

                            {(menu?.name === "Profile" ||
                              menu?.name === "Workspace Page") && (
                              <WORKSPACE_ICON
                                color={
                                  currentURL.includes(
                                    menu?.name?.split(" ")[0]?.toLowerCase(),
                                  )
                                    ? SelectedColor
                                    : DefaultColor
                                }
                              />
                            )}
                            {menu?.name === "Discover talent" && (
                              <DISCOVER_ICON
                                color={
                                  currentURL.includes(
                                    menu?.name?.split(" ")[0]?.toLowerCase(),
                                  )
                                    ? SelectedColor
                                    : DefaultColor
                                }
                              />
                            )}
                            {menu?.name === "Opportunities" && (
                              <OPPORTUNITY_ICON
                                color={
                                  currentURL.includes(menu.name?.toLowerCase())
                                    ? SelectedColor
                                    : DefaultColor
                                }
                              />
                            )}
                            {menu?.name === "Payroll" && (
                              <ROCKET_ICON
                                color={
                                  currentURL.includes(menu.name?.toLowerCase())
                                    ? SelectedColor
                                    : DefaultColor
                                }
                              />
                            )}
                          </div>
                        )}
                        <div
                          className={`mb-0 ${
                            currentURL.includes(
                              menu.name?.split(" ")[0]?.toLowerCase(),
                            )
                              ? "th-primary th-fw-500"
                              : "th-grey-1"
                          }`}>
                          {menu?.url ? (
                            <span className="th-14 th-fw-500">{menu.name}</span>
                          ) : (
                            <span className=" mx-2  text-uppercase th-11 th-fw-700">
                              {menu.name}
                            </span>
                          )}
                        </div>
                        {currentURL?.includes(
                          menu?.name?.split(" ")[0]?.toLowerCase(),
                        ) && (
                          <div
                            className="position-absolute th-bg-primary me-5"
                            style={{
                              width: 3,
                              height: "25px",
                              left: "0%",
                              top: "20%",
                              borderRadius: 5,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
        <div className="profile_switch">
          {/* {currentUser?.is_client ? (
              <>
                <Link
                  to={"https://calendly.com/cogig_india/coffee_chats"}
                  target="_blank">
                  <div
                    className="th-br-8 th-bg-grey"
                    style={{
                      padding: 12,
                    }}>
                    <div className="th-black th-13 th-fw-500 text-start">
                      Need help? Schedule a call, and we&apos;ll help you
                      onboard.
                    </div>
                    <div className="mt-2 text-primary th-14 th-fw-600 text-start ps-2">
                      Schedule a call <ArrowRightOutlined />
                    </div>
                  </div>
                </Link>
                {!currentUser?.is_onboarded && (
                  <div
                    className="th-bg-yellow-1 th-br-8 mt-3"
                    style={{
                      padding: 10,
                    }}>
                    <div className="th-black th-14 mb-1 d-flex align-items-center th-fw-600 gap-2 th-pointer">
                      Complete Onboarding
                    </div>
                    <div
                      className="mt-1 th-grey-1 th-14"
                      style={{
                        textAlign: "left",
                      }}>
                      Update personal details to start creating projects and
                      collaborating with clients.
                    </div>
                    <div
                      className="mt-2 d-flex align-items-center th-primary th-pointer th-14 th-fw-600"
                      onClick={() => {
                        handleRedirectiontoSettings({tabIndex: "1"})
                      }}>
                      Update details &nbsp;
                      <ArrowRightOutlined />
                    </div>
                  </div>
                )}
              </>
            ) : (*/}
          <>
            {!workSpaceListLoading && (
              <>
                <Popover
                  placement="rightBottom"
                  arrow={false}
                  open={showSupportPopover == currentWorkspace?.id}
                  onOpenChange={open => {
                    if (open) {
                      setShowSupportPopover(currentWorkspace.id)
                    } else {
                      setShowSupportPopover(false)
                    }
                  }}
                  content={
                    <Card className="th-bg-white" style={{width: 300}}>
                      <div className="d-flex flex-column gap-3">
                        <div className="th-heading-2">
                          Need help? Schedule a call, and we’ll help you onboard
                          or address any queries you may have.
                        </div>
                        <Button
                          type="primary"
                          className="th-bg-primary"
                          block
                          onClick={() => {
                            window.open(
                              "https://calendly.com/cogig_india/coffee_chats",
                              "_blank",
                            )
                          }}>
                          Schedule a call <ArrowRightOutlined />
                        </Button>
                        <Button
                          type="default"
                          block
                          onClick={() => window.open(TAWK_CHAT)}>
                          Raise a query <ArrowRightOutlined />
                        </Button>
                      </div>
                    </Card>
                  }>
                  <div className={`text-center `}>
                    <div
                      className={`d-flex align-items-center py-2 px-1  position-relative submenu-item th-grey-1 th-br-8`}>
                      <div className="mx-2 d-flex">
                        <SUPPORT_ICON
                          color={
                            showSupportPopover == currentWorkspace?.id
                              ? SelectedColor
                              : DefaultColor
                          }
                        />
                      </div>
                      <div
                        className={`mb-0 ${
                          showSupportPopover == currentWorkspace?.id
                            ? "th-primary"
                            : "th-grey-1"
                        }`}>
                        <span className="th-14 th-fw-500">Support</span>
                      </div>
                    </div>
                  </div>
                </Popover>
                <Link
                  to={
                    currentUser?.is_client
                      ? "/settings"
                      : `${currentMenuItem?.id}/settings`
                  }>
                  <div className={`text-center `}>
                    <div
                      className={`d-flex align-items-center py-2 px-1  position-relative submenu-item ${
                        currentURL.includes("settings".toLowerCase())
                          ? "th-black th-bg-grey th-br-8"
                          : "th-grey-1 th-br-8"
                      }`}>
                      <div className="mx-2 d-flex">
                        <SETTINGS_ICON
                          color={
                            currentURL.includes("settings"?.toLowerCase())
                              ? SelectedColor
                              : DefaultColor
                          }
                        />
                      </div>

                      <div
                        className={`mb-0 ${
                          currentURL.includes("settings"?.toLowerCase())
                            ? "th-primary"
                            : "th-grey-1"
                        }`}>
                        <span className="th-14 th-fw-500">Settings</span>
                      </div>
                      {currentURL?.includes("settings"?.toLowerCase()) && (
                        <div
                          className="position-absolute th-bg-primary me-5"
                          style={{
                            width: 3,
                            height: "25px",
                            left: "0%",
                            top: "20%",
                            borderRadius: 5,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Link>
              </>
            )}

            <Divider className="mt-1 d-none" />
            <div className="d-none flex-column gap-2 px-2" style={{}}>
              <div className="th-black th-14 th-fw-600 d-flex justify-content-around align-items-center">
                <PRO_ICON /> Upgrade to CoGig Pro
              </div>
              <div className="th-grey-1 th-fw-500 th-11">
                Upgrade your personal profile for $1 to unlock premium features
                of CoGig
              </div>
              <div className="text-primary th-14 th-fw-600 text-start ps-1">
                View Plans
              </div>
            </div>
          </>
          {!currentUser?.is_onboarded && (
            <div
              className="th-bg-yellow-1 th-br-8 mt-3"
              style={{
                padding: 10,
              }}>
              <div className="th-black th-14 mb-1 d-flex align-items-center th-fw-600 gap-2 th-pointer">
                Complete Onboarding
              </div>
              <div
                className="mt-1 th-grey-1 th-14"
                style={{
                  textAlign: "left",
                }}>
                Update personal details to start creating projects and
                collaborating with clients.
              </div>
              <div
                className="mt-2 d-flex align-items-center th-primary th-pointer th-14 th-fw-600"
                onClick={() => {
                  handleRedirectiontoSettings({tabIndex: "1"})
                }}>
                Update details &nbsp;
                <ArrowRightOutlined />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default SubMenu
