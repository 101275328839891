/** @format */

import {call, fork, put, takeLatest} from "redux-saga/effects"
import {setUserData} from "../slices/userSlice"
import {setGlobalLoading} from "../slices/globalDataSlice"
import axios from "axios"
import {message} from "antd"
import {BASE_URL} from "../../utils/apiConfig"
import {setCurrencyList} from "../slices/currencyListSlice"

const loginUser = async (username, password, is_client) => {
  let payload = {
    password: password,
    email: username,
    is_client: is_client ?? false,
  }
  if (localStorage.getItem("map_token")) {
    payload["map_token"] = localStorage.getItem("map_token")
  }
  const response = await axios.post("/login/", payload, {
    headers: {
      Authorization: "",
    },
  })
  if (response.status === 200) {
    localStorage.setItem("token", response.data.access_token)
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.access_token}`

    return response.data.access_token
  } else {
    return false
  }
}
const fetchUserData = async () => {
  const response = await axios.get(`${BASE_URL}current-user/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
  if (response.status === 200) {
    let userDetails = {
      ...response?.data,
      avatar: response?.data?.avtar,
      token: localStorage.getItem("token"),
      is_client: localStorage.getItem("navigateToDash")
        ? false
        : localStorage.getItem("proposal_id") !== null
        ? localStorage.getItem("redirection_workspace") !== null
          ? false
          : true
        : response?.data?.is_client,
      authorized: true,
    }
    return userDetails
  } else {
    return false
  }
}
const fetchCurrencyListData = async () => {
  const response = await axios.get(`${BASE_URL}projects/currency/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
  if (response.status === 200) {
    let listData = response?.data

    return listData
  } else {
    return false
  }
}
function* userLogin({payload}) {
  const {username, password, is_client} = payload
  yield put(setGlobalLoading(true))
  try {
    const userToken = yield call(loginUser, username, password, is_client)
    if (userToken) {
      let payload = {token: userToken, authorized: true}
      yield put(setUserData(payload))
      yield fork(GetCurrentUserData)
    } else {
    }
  } catch (e) {
    message.error(e?.response?.data?.message ?? "Login Failed")
  } finally {
    yield put(setGlobalLoading(false))
  }
}
function* userLogOut() {
  const queryParams = new URLSearchParams(window.location.search)
  const isNewClient = queryParams.get("client")
  yield put(setGlobalLoading(true))
  localStorage.clear()
  sessionStorage.clear()
  yield put(setUserData({}))
  if (isNewClient !== "true") {
    window.location.replace("/auth/login")
  }
  yield put(setGlobalLoading(false))
}
function* GetCurrentUserData() {
  yield put(setGlobalLoading(true))
  localStorage.removeItem("email")
  try {
    const userData = yield call(fetchUserData)

    if (userData) {
      yield put(setUserData(userData))
    } else {
      message.error("wrong Credentials1")
    }
  } catch (e) {
    console.log("Login failed")
  } finally {
    yield put(setGlobalLoading(false))
  }
}
function* GetCurrenyListData() {
  try {
    const currencyList = yield call(fetchCurrencyListData)
    if (currencyList) {
      yield put(setCurrencyList(currencyList))
    } else {
      console.error("Currency List not fetched")
    }
  } catch (e) {
    console.log("Currency List failed")
  } finally {
  }
}
function* userSaga() {
  yield takeLatest("USER_LOGIN", userLogin)
  yield takeLatest("USER_LOGOUT", userLogOut)
  yield takeLatest("GET_USER_DATA", GetCurrentUserData)
  yield call(GetCurrentUserData) // run once on app load to check user auth
  yield call(GetCurrenyListData) // run once on app load to fecth currency auth
}
export default userSaga
